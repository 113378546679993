// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-gallery-bevel-memorials-tsx": () => import("./../../../src/pages/gallery/bevel-memorials.tsx" /* webpackChunkName: "component---src-pages-gallery-bevel-memorials-tsx" */),
  "component---src-pages-gallery-bronze-plaque-tsx": () => import("./../../../src/pages/gallery/bronze-plaque.tsx" /* webpackChunkName: "component---src-pages-gallery-bronze-plaque-tsx" */),
  "component---src-pages-gallery-flat-memorials-tsx": () => import("./../../../src/pages/gallery/flat-memorials.tsx" /* webpackChunkName: "component---src-pages-gallery-flat-memorials-tsx" */),
  "component---src-pages-gallery-granite-benches-tsx": () => import("./../../../src/pages/gallery/granite-benches.tsx" /* webpackChunkName: "component---src-pages-gallery-granite-benches-tsx" */),
  "component---src-pages-gallery-granite-colors-tsx": () => import("./../../../src/pages/gallery/granite-colors.tsx" /* webpackChunkName: "component---src-pages-gallery-granite-colors-tsx" */),
  "component---src-pages-gallery-pet-memorials-tsx": () => import("./../../../src/pages/gallery/pet-memorials.tsx" /* webpackChunkName: "component---src-pages-gallery-pet-memorials-tsx" */),
  "component---src-pages-gallery-slant-memorials-tsx": () => import("./../../../src/pages/gallery/slant-memorials.tsx" /* webpackChunkName: "component---src-pages-gallery-slant-memorials-tsx" */),
  "component---src-pages-gallery-upright-memorials-tsx": () => import("./../../../src/pages/gallery/upright-memorials.tsx" /* webpackChunkName: "component---src-pages-gallery-upright-memorials-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../../../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

